<template>
  <div>
        <!-- 创建 -->
        <div style="width:1000px;margin:0 auto;background:#fff;text-align:left;">
            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">基本信息</div>
            </div>
            <el-input placeholder="请输入内容" v-model="saveData.customer_code" size="small" class="baseInput">
                <template slot="prepend">客户编码</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.customer_name" size="small" class="baseInput">
                <template slot="prepend">客户名称</template>
            </el-input>
            <div class="add_label">
                默认仓库：<el-select size="small" v-model="saveData.default_warehouse_id">
                        <el-option
                            v-for="item in storeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
            </div>
            <div class="add_label">
                级别：<el-select size="small" v-model="saveData.grade_id">
                        <el-option
                            v-for="item in gradeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
            </div>
            <div class="add_label">
                标签：<el-select size="small" v-model="saveData.label_id">
                        <el-option
                            v-for="item in labelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
            </div>
            <div class="add_label">
                分类：<el-select size="small" v-model="saveData.classify_id">
                        <el-option
                            v-for="item in cateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
            </div>

            <div  style="width:550px;display:inline-block;position:relative;top:0px;left:20px;">
                <areaSelect :province="saveData.province"
                        :city="saveData.city"
                        :country="saveData.area"
                        @changeData="changeData"></areaSelect>
            </div>
            <el-input placeholder="请输入内容" v-model="saveData.address" size="small" class="baseInput" style="width:855px;margin-top:0px;">
                <template slot="prepend">详细地址</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.postcode" size="small" class="baseInput">
                <template slot="prepend">邮编</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.remarks" size="small" class="baseInput" style="width:535px;">
                <template slot="prepend">备注</template>
            </el-input>
            <div class="add_label">
                客户状态：<el-switch v-model="saveData.enable_status"></el-switch>
            </div>
            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">联系人信息</div>
            </div>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_name" size="small" class="baseInput">
                <template slot="prepend">名称</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_phone" size="small" class="baseInput">
                <template slot="prepend">手机号码</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_department" size="small" class="baseInput">
                <template slot="prepend">部门</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_position" size="small" class="baseInput">
                <template slot="prepend">职位</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_email" size="small" class="baseInput">
                <template slot="prepend">E-mail</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.contacts_qq" size="small" class="baseInput">
                <template slot="prepend">QQ</template>
            </el-input>            

            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">财务信息</div>
            </div>
            <el-input placeholder="请输入内容" v-model="saveData.invoice_title" size="small" class="baseInput">
                <template slot="prepend">发票抬头</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.identification" size="small" class="baseInput">
                <template slot="prepend">纳税人识别号</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.bank_name" size="small" class="baseInput">
                <template slot="prepend">开户行</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.account_name" size="small" class="baseInput">
                <template slot="prepend">开户名称</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.bank_account" size="small" class="baseInput">
                <template slot="prepend">银行账号</template>
            </el-input>      
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button  @click="exit();">关 闭</el-button>
                <el-button type="primary" @click="save();">提 交</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import areaSelect from "../../../components/common/Area.vue";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    components:{
        areaSelect
    },
    data(){
        return {
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),

            //源
            storeList:[],
            gradeList:[],
            labelList:[],
            cateList:[],

            saveDataInit:null,
            saveData:{
                customer_code:'',
                customer_name:'',
                default_warehouse_id:'',
                grade_id:'',
                label_id:'',
                classify_id:'',
                province:0,
                city:0,
                area:0,
                address:'',
                postcode:'',
                remarks:'',
                enable_status:true,
                contacts_name:'',
                contacts_phone:'',
                contacts_department:'',
                contacts_position:'',
                contacts_email:'',
                contacts_qq:'',
                invoice_title:'',
                identification:'',
                bank_name:'',
                account_name:'',
                bank_account:''
            }
        }
    },
    destroyed(){
        this.saveData = tools.copy(this.saveDataInit);
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        changeData(type,parent_name){
            if (type === 2) {
                this.saveData.province = parent_name;
            } else if (type === 3) {
                this.saveData.city = parent_name;
            } else if (type === 4) {
                this.saveData.area = parent_name;
            }
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.customerSave(this.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }
            //新增
            apis.customerAdd(this.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.$message({
                    type:'success',
                    message:'添加成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }

        //获取各种数据源
        apis.getCustomrSource().then(res=>{
            this.storeList = res.data.warehouse;
            this.gradeList = res.data.grade;
            this.labelList = res.data.label;
            this.cateList = res.data.classify;

        }).catch(err=>{

        });
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.customerDetail({id:this.id}).then((res)=>{
               res.data.password = ''; 
                this.saveData = res.data;
                this.saveData.id = this.id;
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });
        }
    }
}
</script>

<style scoped lang="less">
    @import url('../../../assets/less/normal');
    .baseInput{
      width:280px;margin:15px 20px;
  }
  .add_label{
      display:inline-block;margin:15px 0 15px 20px;width:300px;
  }
</style>